/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CategorySubItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding-block-end: 22px;
    margin: 0;

    &:hover {
        .CategorySubItem {
            &-Text {
                @include desktop {
                    color: var(--color-black);
                    transform: translateX(16px);
                }
            }
        }
    }

    &:focus {
        .CategorySubItem {
            &-Text {
                color: var(--color-black);
                transform: translateX(16px);
            }
        }
    }

    &::before {
        content: '';
    }

    &:last-child {
        margin: 0;
    }

    &_isLink {
        padding-block-end: 0;
    }

    &-Link {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-block-end: 22px;
    }

    &-Text {
        text-transform: uppercase;
        color: var(--primary-base-color);
        font-weight: 600;
        font-size: 0.875rem; // 14px
        margin: 0;
        transition: transform 0.3s ease;
    }

    &-hide {
        display: none;
    }

    &-show {
        display: block;
    }

    &_isHidden {
        display: none;
    }
}

.Menu {
    &-SubCategories {
        .CategoryItem-Text {
            letter-spacing: 0.08em;
        }
    }
}

.CategoryItem {
    &-Text {
        text-transform: uppercase;
        color: var(--primary-base-color);
        font-weight: 600;
        font-size: 0.875rem; // 14px
        margin: 0;
        transition: transform 0.3s ease;

        &_isHidden {
            display: none;
        }
    }

    &-ChevronIconContainer {
        height: 24px;
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
    
        &_isHidden {
            display: none;
        }
    }

    &_isHidden {
        display: none;
    }

    &_isDisplayed {
        display: flex;
    }

    &-Wrapper {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        &_isHidden {
            display: none;
        }
    }
}

.SubCategoryList {
    display: block;

    &_isHidden {
        display: none;
    }
}
