/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@mixin mini-mobile {
    @media (min-width: 361px) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin narrow-desktop-plus {
    @media (min-width: 1024px) {
        @content;
    }
}

@mixin before-narrow-desktop-plus {
    @media (max-width: 1023px)
        and (-webkit-min-device-pixel-ratio: 1) {
        @content;
    }
}

@mixin widescreen {
    @media (min-width: 1440px) {
        @content;
    }
}

@mixin large-widescreen {
    @media (min-width: 2040px) {
        @content;
    }
}

@mixin ultra-narrow-desktop {
    @media (min-width: 744px)
        and (max-width: 1160px) {
        @content;
    }
}

@mixin narrow-desktop {
    @media (min-width: 1200px)
        and (max-width: 1280px) {
        @content;
    }
}

@mixin large-narrow-desktop {
    @media (min-width: 1200px)
        and (max-width: 1300px) {
        @content;
    }
}

@mixin wide-desktop {
    @media (min-width: 1280px) {
        @content;
    }
}

@mixin ultra-wide-desktop {
    @media (min-width: 1480px) {
        @content;
    }
}

@mixin before-desktop {
    @media (max-width: 1199px)
        and (-webkit-min-device-pixel-ratio: 1) {
        @content;
    }
}

@mixin ultrascreen-desktop {
    @media (min-width: 1485px) and (max-width: 1540px) {
        @content;
    }
}

@mixin widescreen-desktop {
    @media (min-width: 1441px) and (max-width: 1484px) {
        @content;
    }
}

@mixin medium-desktop {
    @media (min-width: 1201px) and (max-width: 1440px) {
        @content;
    }
}

@mixin mobile-tablet {
    @media (max-width: 1200px) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: 811px)
        and (max-width: 1199px)
        and (-webkit-min-device-pixel-ratio: 1) {
        @content;
    }
}

@mixin tablet-portrait {
    @media (min-width: 811px)
        and (max-width: 1023px)
        and (-webkit-min-device-pixel-ratio: 1)
        and (orientation: portrait) {
        @content;
    }
}

@mixin tablet-landscape {
    @media(min-width: 811px)
        and (max-width: 1023px)
        and (-webkit-min-device-pixel-ratio: 1)
        and (orientation: landscape) {
        @content;
    }
}

@mixin small-tablet {
    @media (min-width: 744px)
        and (max-width: 810px)
        and (-webkit-min-device-pixel-ratio: 1) {
        @content;
    }
}

@mixin medium-tablet {
    @media (min-width: 744px)
        and (max-width: 960px)
        and (-webkit-min-device-pixel-ratio: 1) {
        @content;
    }
}

@mixin medium-tablet-only {
    @media (min-width: 811px)
        and (max-width: 960px)
        and (-webkit-min-device-pixel-ratio: 1) {
        @content;
    }
}

@mixin large-tablet {
    @media(min-width: 811px) and (max-width: 1023px) {
        @content;
    }
}

@mixin tablet-desktop {
    @media (min-width: 744px) {
        @content;
    }
}

@mixin medium-tablet-desktop {
    @media (min-width: 944px) {
        @content;
    }
}

@mixin after-mobile {
    @media screen and (min-width: 811px) {
        @content;
    }
}

@mixin mobile {
    @media screen and (max-width: 810px) {
        @content;
    }
}

@mixin standalone {
    /* stylelint-disable-next-line media-feature-name-no-unknown */
    @media all and (display-mode: standalone) {
        @content;
    }
}

@mixin hoverable {
    @media (hover: hover) and (pointer: fine) {
        @content;
    }
}

@mixin nano-mobile {
    @media screen and (max-width: 380px) {
        @content;
    }
}
