/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

%loader {
    position: relative;
    width: 40px;
    height: 23px;
    background-color: #fff;
    margin: 12px 0;
    border-inline-start: solid 5px var(--primary-base-color);
    border-inline-end: solid 5px var(--primary-base-color);
    box-sizing: border-box;
    animation: rotate 2s linear infinite;
    transform-origin: 50% 50%;

    &::before,
    &::after {
        content: '';
        position: absolute;
        z-index: 1;
        width: 28px;
        height: 28px;
        transform: scaleY(.5774) rotate(-45deg);
        background-color: inherit;
        inset-inline-start: 1px;
        box-sizing: border-box;
    }

    // ltr + rtl adjusted, don't replace 'left' and 'right' here

    &::before {
        inset-block-start: -14px;
        border-top: solid 7px var(--primary-base-color);
        border-right: solid 7px var(--primary-base-color);
    }

    &::after {
        inset-block-end: -14px;
        border-bottom: solid 7px var(--primary-base-color);
        border-left: solid 7px var(--primary-base-color);
    }

    span {
        position: absolute;
        inset-block: 0;
        inset-inline-start: 0;
        inset-inline-end: 0;
        margin: auto;
        width: 18px;
        height: 18px;
        background-color: rgb(80, 88, 101);
        border-radius: 50%;
        z-index: 2;
    }
}

// Setting
$LOADER_S_Tail: 5deg;
$LOADER_S_Body: 270deg;
$LOADER_SPEED: 1333ms;
$LOADER_TIMEING_FUNCTION: cubic-bezier(0.4, 0.0, 0.2, 1);

// Loader Stroke Degree
$LOADER_S_AIR: 360deg - $LOADER_S_Body;
$LOADER_S_S: $LOADER_S_Tail * 2;
$LOADER_S_L: $LOADER_S_Tail * 2 + $LOADER_S_Body;

// Default
$LOADER_SIZE: 1em * (43 / 14);
$LOADER_STROKE_WIDTH: 1em * (1 / 14);
$LOADER_FOOT: 360deg * (3 / 5);
$LOADER_CIRCLE_ROTATE_SPEED: 360deg / ($LOADER_FOOT + $LOADER_S_AIR) * $LOADER_SPEED;

// Loader Stroke Rotate Step
@function GCD($a, $b) {
    @if $b == 0 {
        @return $a;
    }

    @else {
        @return GCD($b, $a % $b);
    }
}

@function LCM($a, $b) {
    @return $a * $b / GCD($a, $b);
}

$LOADER_STROKE_ROTATE_STEP: LCM(360, $LOADER_S_Body / 1deg) / ($LOADER_S_Body / 1deg);

// Keyframes
@keyframes L_circle_rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes L_stroke_rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate($LOADER_S_Body * $LOADER_STROKE_ROTATE_STEP);
    }
}

@keyframes L_stroke_fix {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate($LOADER_S_Body / 2);
    }

    100% {
        transform: rotate($LOADER_S_Body);
    }
}

@keyframes L_stroke_Left_grow {
    0% {
        transform: rotate(0deg - $LOADER_S_S / 2);
    }

    50% {
        transform: rotate(0deg - $LOADER_S_L / 2);
    }

    100% {
        transform: rotate(0deg - $LOADER_S_S / 2);
    }
}

@keyframes L_stroke_Right_grow {
    0% {
        transform: rotate($LOADER_S_S / 2);
    }

    50% {
        transform: rotate($LOADER_S_L / 2);
    }

    100% {
        transform: rotate($LOADER_S_S / 2);
    }
}

// Animation Function
@function L_circle_rotate_F($speed) {
    @return (
        /* animation-name:            */
        L_circle_rotate /* animation-duration:        */
        $speed /* animation-timing-function: */
        linear /* animation-iteration-count: */
        infinite /* animation-fill-mode:       */
        both);
}

@function L_stroke_rotate_F() {
    @return (
        /* animation-name:            */
        L_stroke_rotate /* animation-duration:        */
        $LOADER_SPEED * $LOADER_STROKE_ROTATE_STEP /* animation-timing-function: */
        steps($LOADER_STROKE_ROTATE_STEP) /* animation-iteration-count: */
        infinite /* animation-fill-mode:       */
        both);
}

@function L_stroke_fix_F() {
    @return (
        /* animation-name:            */
        L_stroke_fix /* animation-duration:        */
        $LOADER_SPEED /* animation-timing-function: */
        $LOADER_TIMEING_FUNCTION /* animation-iteration-count: */
        infinite /* animation-fill-mode:       */
        both);
}

@function L_stroke_side_grow_F($side) {
    @return (
        /* animation-name:            */
        L_stroke_#{$side}_grow /* animation-duration:        */
        $LOADER_SPEED /* animation-timing-function: */
        $LOADER_TIMEING_FUNCTION /* animation-iteration-count: */
        infinite /* animation-fill-mode:       */
        both);
}

// Style Extend
@function other_side($side) {
    @if $side == Right {
        @return Left;
    }

    @else if $side == Left {
        @return Right;
    }
}

%before {
    content: "";
    display: block;
}

%loader_size {
    width: $LOADER_SIZE;
    height: $LOADER_SIZE;
    box-sizing: border-box;
    border-radius: 50%;
}

%loader_stroke {
    border-style: solid;
    border-width: $LOADER_STROKE_WIDTH;
    border-color: currentColor;
}

%loader_stroke_clip {
    &_Right {
        position: absolute;
        clip: rect(0 $LOADER_SIZE $LOADER_SIZE $LOADER_SIZE / 2);
    }

    &_Left {
        position: absolute;
        clip: rect(0 $LOADER_SIZE / 2 $LOADER_SIZE 0);
    }

    &_Mid {
        position: absolute;
        clip: rect(0 $LOADER_SIZE / 2 + .05em $LOADER_SIZE / 2 $LOADER_SIZE / 2 - .05em);
    }
}

@mixin loader_stroke_rotate_step($class_name, $step) {
    $foot: 360deg * $step;
    $speed: 360deg / ($foot + $LOADER_S_AIR) * $LOADER_SPEED;

    &.#{$class_name} {
        animation: L_circle_rotate_F($speed);
    }
}
